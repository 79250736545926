@tailwind base;
@tailwind components;
@tailwind utilities;

.tc-container {
  @apply 2xl:w-[1356px] lg:w-[972px] px-3 lg:px-0;
}

/*
  Hiding reCAPTCHA badge
  @see https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
*/
.grecaptcha-badge {
  visibility: hidden;
}
